import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import Helmet from 'react-helmet'

import AltertineLayout from './layouts/AltertineLayout'

import Button from '@material-ui/core/Button'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

const IndexPage = () => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)
  return (
    <AltertineLayout image="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/ping%20pong.jpeg">
      <Helmet
        title="Tennis Couvert - Tennis Club Uccle Churchill"
        meta={[
          {
            name: 'description',
            content:
              "Vous souhaitez jouer au tennis cet hiver? Découvrez ici toutes les informations pratiques liées à la saison d'hiver"
          },
          { name: 'charset', content: 'UTF-8' }
        ]}
      />
      <section className="project_descr">
        <div className="container mt-100">
          <div className="row center">
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">Tennis couvert</h2>
                <p className="module-subtitle">
                  En hiver, nous mettons à votre disposition 2 terrains{' '}
                  <strong>couverts</strong> en terre battue.
                </p>
                <p className="module-subtitle">
                  La saison d’hiver s’étend du début du mois d’octobre à la fin
                  du mois de mars.
                </p>
              </div>
            </div>
          </div>

          <div className="row center" style={{ marginTop: '7em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">Conditions et Tarifs</h2>
                <p className="module-subtitle">
                  La saison d’hiver s’étend du début du mois d’octobre à la fin
                  du mois de mars.
                </p>
              </div>
            </div>
          </div>
          <div className="row center">
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <table>
                <thead>
                  <tr>
                    <th>Du lundi au vendredi</th>
                    <th>
                      Terrains Fixes
                      <br />
                      (réservation sur 6 mois)
                    </th>
                    <th>
                      Terrains Occasionnels <br /> (heures volantes)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="lundi-vendredi">De 9h à 12h</td>
                    <td>15&euro; /heure</td>
                    <td>24&euro; /heure</td>
                  </tr>
                  <tr>
                    <td className="lundi-vendredi">De 12h à 17h</td>
                    <td>16&euro; /heure</td>
                    <td>24&euro; /heure</td>
                  </tr>
                  <tr>
                    <td className="lundi-vendredi">De 17h à 18h</td>
                    <td>20&euro; /heure</td>
                    <td>24&euro; /heure</td>
                  </tr>
                  <tr>
                    <td className="lundi-vendredi">De 18h à 22h</td>
                    <td>28&euro; /heure</td>
                    <td>30&euro; /heure</td>
                  </tr>
                  <tr>
                    <td className="lundi-vendredi">De 22h à 23h</td>
                    <td>20&euro; /heure</td>
                    <td>24&euro; /heure</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row center" style={{ marginTop: '4em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <table>
                <thead>
                  <tr>
                    <th>Week-end</th>
                    <th>
                      Terrains Fixes
                      <br />
                      (réservation sur 6 mois)
                    </th>
                    <th>
                      Terrains Occasionnels <br /> (heures volantes)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="week-end">De 9h à 22h*</td>
                    <td>25&euro; /heure</td>
                    <td>30&euro; /heure</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row center" style={{ marginTop: '4em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <table>
                <thead>
                  <tr>
                    <th>Jours fériés</th>
                    <th>
                      Terrains Fixes
                      <br />
                      (réservation sur 6 mois)
                    </th>
                    <th>
                      Terrains Occasionnels <br /> (heures volantes)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="jours-feries">De 9h à 22h*</td>
                    <td>Selon le tarif réservé</td>
                    <td>30&euro; /heure</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row center" style={{ marginTop: '3em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p></p>
            </div>
          </div>
          <div className="row center" style={{ marginTop: '5em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <p className="module-subtitle">
                  Intéressé pour une réservation permanente en hiver ? (6 mois)
                </p>
              </div>
            </div>
          </div>
          <div className="row center" style={{ marginBottom: '7em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p>
                <a
                  href="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/documents/Hiver%202021.2022_Disponibilit%C3%A9s%20et%20tarifs.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                >
                  Veuillez consulter la liste des disponibilités
                </a>
                .  (Cette liste est mise à jour pour la 1ère fois le 15 juin)
              </p>
              <p>
                N’hésitez pas à faire, sans engagement de votre part, une{' '}
                <a
                  href="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/documents/3_Contrat%20re%CC%81servation%20TC%20Churchill_2022.2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                >
                  demande de réservation pour un abonnement hiver
                </a>
                .
              </p>
            </div>
          </div>
          <div className="row center" style={{ marginTop: '5em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <p className="module-subtitle">
                  Vous souhaitez jouer de temps en temps en hiver et profiter
                  des heures non réservées par les abonnés hiver ?
                </p>
              </div>
            </div>
          </div>
          <div className="row center" style={{ marginBottom: '7em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p>
                Dans tous les cas, le paiement du terrain s’effectue à la
                réservation, avant de jouer
              </p>
              <p>
                Passez au club house pendant le week-end pour réserver et payer
                votre terrain ou appelez le{' '}
                <a
                  href="tel:+32473286166"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                >
                  0473 / 28 61 66
                </a>{' '}
                ou encore, envoyez un mail à{' '}
                <a
                  href="mailto:tennischurchill1180@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                >
                  tennischurchill1180@gmail.com
                </a>
                .
              </p>
              <p>
                Si, d’une manière impromptue, vous souhaitez jouer en hiver
                en semaine de 9h à 17h pour pouvez acheter sur notre site de
                réservation un ticket (24,00€) et réserver un terrain en semaine
                de 9h à 17h à l’aide de{' '}
                <a
                  href="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/documents/Proce%CC%81dure%20heures%20volantes%20en%20hiver_2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                >
                <span>la procédure de réservation et de paiement online</span>
                </a>{' '}
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </AltertineLayout>
  )
}

export default IndexPage
